import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  language: string = '';
  constructor(
    public notificationService: NotificationService,
    public router: Router,
  ) {

  }

  ngOnInit(): void {
    this.language = environment.language;
  }

  redirectTo(uri: string): void {
    this.router.navigateByUrl('/', {skipLocationChange: false}).then(() => {
      this.router.navigate([uri]);
    });
  }

  changeLanguage(lng: string): void {
    if (environment.production) {
      window.location.href = environment.frontendUrl + lng + this.router.url;
    } else {
      const url = environment.frontendUrl.substring(0, environment.frontendUrl.length - 2);
      if (lng === 'es') {
        window.location.href = url + '2' + this.router.url;
      } else if (lng === 'en') {
        window.location.href = url + '1' + this.router.url;
      }
    }
  }
}
