import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  // TODO: corregir esto para que use los perfiles
  grafanaUrl: string = environment.grafanaUrl;
  //panelUrl: string = "goto/hzI7lpWSR?orgId=1";
  // panelUrl: string = 'd-solo/f0e859d9-51b5-4f38-9f1d-44c35b9a9f6a/informacion-general?orgId=1&from=1696403608582&to=1696425208582&theme=light&panelId=1';
  //muestra los datos de las últimas 6h
  panelUrl = `d-solo/f0e859d9-51b5-4f38-9f1d-44c35b9a9f6a/informacion-general?orgId=2&refresh=30s&from=now-6h&to=now&theme=light&panelId=`
  grafanaUrlSafe!: SafeResourceUrl;
  WeatherGrafanaURL: any;
  AirGrafanaURL: any;
  WaterGrafanaURL: any
  selectedSensor:number=1
  selectedTime:number=12
  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    // eslint-disable-next-line max-len
    // console.log(environment)
    // console.log(this.grafanaUrl+this.panelUrl)
    // this.grafanaUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + this.panelUrl);
    // this.grafanaUrlSafe = "http://dev-smartcity.tcsa.es/gf";
    this.WeatherGrafanaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + this.panelUrl + 3)
    this.AirGrafanaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + this.panelUrl + 2)
    this.WaterGrafanaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + this.panelUrl + 1)
  }

  onTimeChange(value: any) {
    const url = `d-solo/f0e859d9-51b5-4f38-9f1d-44c35b9a9f6a/informacion-general?orgId=2&refresh=30s&from=now-${value}h&to=now&theme=light&panelId=`
    this.WeatherGrafanaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + url + 3)
    this.AirGrafanaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + url + 2)
    this.WaterGrafanaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.grafanaUrl + url + 1)
    console.log("es valor es ", value)
    this.selectedTime=value
  }
  onSensorChange(value: any) {
   this.selectedSensor=value
  }

}
