<div class="header">
  <div class="logos">
    <button class="sidenav" mat-icon-button>
      <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
    </button>
  </div>
  <div class="title" style="display: flex;flex-direction: row; align-items: center; gap: 15px;">
    <p class="logo-title" style="    font-weight: 600;
    font-size: 19px;
    margin-top: 10px;">Sistema Redundante de Control de Varada</p>
    <img class="logo" src="assets/logo/new_logo_zamakona.png" alt="Logo Técnicas Competitivas" height="50px"
      (click)="redirectTo('/')">
    <!--<span (click)="redirectTo('home')">Técnicas Competitivas</span>-->
  </div>

  <!-- <div class="settings">
    <button (click)="redirectTo('notifications')" mat-icon-button aria-label="Notifications">
      <i class="material-icons"> notifications </i>
      <span class="material-icons circle-red" *ngIf="notificationService.notificationsNotRead > 0"> circle </span>
    </button>

    <button mat-icon-button [matMenuTriggerFor]="menuDesktop" aria-label="Desktop Menu">
      <div class="usuario">
        <i class="material-icons">person</i>
      </div>
    </button>
    <mat-menu #menuDesktop="matMenu">
      <button class="user-options" mat-menu-item>
        <i class="material-icons">exit_to_app</i>
        <span i18n>Exit</span>
      </button>
    </mat-menu>

    <ng-container class="language">
      <button mat-button [matMenuTriggerFor]="flag" class="language-menu">
        <img *ngIf="language === 'es'" src="assets/flags/es.png" alt="spanish flag">
        <img *ngIf="language === 'en'" src="assets/flags/en.png" alt="english flag">
      </button>
      <mat-menu #flag="matMenu" class="language-drop">
        <button class="flag" mat-menu-item (click)="changeLanguage('es')">
          <img src="assets/flags/es.png" alt="spanish flag">
        </button>
        <button class="flag" mat-menu-item (click)="changeLanguage('en')">
          <img src="assets/flags/en.png" alt="english flag">
        </button>
      </mat-menu>
    </ng-container>
  </div> -->
</div>

<mat-sidenav-container>
  <mat-sidenav class="sidenav" #sidenav>
    <mat-nav-list>
      <a mat-list-item (click)="sidenav.toggle(); redirectTo('') ">
        <span class="material-icons"> public </span>
        <h2>Mapa</h2>
      </a>
      <a mat-list-item (click)="sidenav.toggle(); redirectTo('zamakona-sensors') ">
        <span class="material-icons"> sensors </span>
        <h2 i18n>Sensors</h2>
      </a>
      <a mat-list-item (click)="sidenav.toggle(); redirectTo('zamakona-cameras') ">
        <span class="material-icons"> camera_alt </span>
        <h2 >Cámaras PTZ</h2>
      </a>
      <a mat-list-item (click)="sidenav.toggle(); redirectTo('zamakona-plc') ">
        <span class="material-icons">  directions_boat </span>
        <h2 >Equipo PLC</h2>
      </a>
      <a mat-list-item (click)="sidenav.toggle(); redirectTo('dashboard') ">
        <span class="material-icons"> show_chart </span>
        <h2 i18n>Historic Dashboard</h2>
      </a>


      <!-- //estaba comentado anteriormente -->

      <!-- <a mat-list-item (click)="sidenav.toggle(); redirectTo('current-dashboard') ">
        <span class="material-icons"> speed </span>
        <h2 i18n>Current Dashboard</h2>
      </a> -->

      <!-- <a mat-list-item (click)="sidenav.toggle(); redirectTo('sensors-types') ">
        <span class="material-icons"> format_list_bulleted </span>
        <h2 i18n>Sensor Types</h2>
      </a> -->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>

    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>





