import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RampService } from 'src/app/services/zamakona/ramp.service';
import { Subscription } from 'rxjs';
import { ResponseRamp5 } from 'src/app/interfaces/ResponseRamp5';
import { ResponseRamp4 } from 'src/app/interfaces/ResponseRamp4';
@Component({
  selector: 'app-zamakona-plc-detail',
  templateUrl: './zamakona-plc-detail.component.html',
  styleUrls: ['./zamakona-plc-detail.component.css']
})
export class ZamakonaPlcDetailComponent implements OnInit, OnDestroy {
  interval: any;
  constructor(private route: ActivatedRoute,
    private rampService: RampService
  ) {
  }
  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  routeSub: Subscription = new Subscription();
  rampData: any;
  id: number = 0;



  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      this.id = params['plcID'];

      if (this.id == 1) {
        this.getDataFromRamp4()
        this.interval = setInterval(() => {
          this.getDataFromRamp4()
        }, 30000)
      }
      if (this.id == 2) {
        this.getDataFromRamp5()
        this.interval = setInterval(() => {
          this.getDataFromRamp5()
        }, 30000)
      }


    });
  }


  getDataFromRamp5() {
    const promise = new Promise<void>((resolve, reject) => {
      this.rampService.getRamp5("Ramp_2").subscribe((response: ResponseRamp5) => {
        this.rampData = response as ResponseRamp5
      
        resolve();
      }, (error) => {
        reject(error);

      });
    });
    return promise;
  }

  getDataFromRamp4() {
    const promise = new Promise<void>((resolve, reject) => {
      this.rampService.getRamp4("Ramp_1").subscribe((response: ResponseRamp4) => {
        this.rampData = response as ResponseRamp4
        
        resolve();
      }, (error) => {
        reject(error);

      });
    });
    return promise;
  }



}
