import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';

import { ActionsComponent } from './components/rooms/actions/actions.component';
import { AdvancedAmbienceDetailComponent } from
  './components/sensors/advanced-ambience-detail/advanced-ambience-detail.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BuildingDialogComponent } from
  './components/buildings/building-dialog/building-dialog.component';
import { BuildingTypePipe } from './pipes/building-type.pipe';
import { BuildingsComponent } from './components/buildings/buildings.component';
import { CachedSrcDirective } from './components/dashboard/cached-src-directive.component';
import { CurrentDashboardComponent }
  from './components/current-dashboard/current-dashboard.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DatePipe } from '@angular/common';
import { DialogRoomMapComponent } from
  './components/room-map/dialog-room-map/dialog-room-map.component';
import { GaugeChartModule } from 'angular-gauge-chart';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientModule } from '@angular/common/http';
import { MagneticDetailComponent } from
  './components/sensors/magnetic-detail/magnetic-detail.component';
import { MapComponent } from './components/map/map.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { NgModule } from '@angular/core';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PaginatorInternational } from './shared/paginator-international';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RoomDialogComponent } from './components/rooms/room-dialog/room-dialog.component';
import { RoomMapComponent } from './components/room-map/room-map.component';
import { RoomTypePipe } from './pipes/room-type.pipe';
import { RoomsComponent } from './components/rooms/rooms.component';
import { SensorDialogComponent } from './components/sensors/sensor-dialog/sensor-dialog.component';
import { SensorsComponent } from './components/sensors/sensors.component';
import { SensorsTypesComponent } from './components/sensors-types/sensors-types.component';
import { SwitchDetailComponent } from './components/sensors/switch-detail/switch-detail.component';
import { ZamakonaSensorsComponent } from './components/zamakona-sensors/zamakona-sensors.component';
import { environment } from './../environments/environment';
import { ZamakonaSensorDetailDialogComponent } from './components/zamakona-sensors/zamakona-sensor-detail-dialog/zamakona-sensor-detail-dialog.component';
import { ZamakonaWaterQualityDetailComponent } from './components/zamakona-sensors/zamakona-sensor-detail-dialog/zamakona-water-quality-detail/zamakona-water-quality-detail.component';
import { ZamakonaAirQualityDetailComponent } from './components/zamakona-sensors/zamakona-sensor-detail-dialog/zamakona-air-quality-detail/zamakona-air-quality-detail.component';
import { ZamakonaWeatherStationDetailComponent } from './components/zamakona-sensors/zamakona-sensor-detail-dialog/zamakona-weather-station-detail/zamakona-weather-station-detail.component';
import { ZamakonaSensorRealTimeDashboardComponent } from './components/zamakona-sensors/zamakona-sensor-real-time-dashboard/zamakona-sensor-real-time-dashboard.component';
import { ZamakonaCamerasComponent } from './components/zamakona-cameras/zamakona-cameras/zamakona-cameras.component';
import { ZamakonaCameraDetailComponent } from './components/zamakona-cameras/zamakona-camera-detail/zamakona-camera-detail.component';
import { ZamakonaPlcComponent } from './components/zamakona-plc/zamakona-plc.component';
import { ZamakonaPlcDetailComponent } from './components/zamakona-plc/zamakona-plc-detail/zamakona-plc-detail.component';
import { MatTreeModule } from '@angular/material/tree';
import { ZamakonaSensorSpeedmeterComponent } from './components/zamakona-sensors/zamakona-sensor-speedmeter/zamakona-sensor-speedmeter.component';
import { ZamakonaSensorCompassComponent } from './components/zamakona-sensors/zamakona-sensor-compass/zamakona-sensor-compass.component';
import { ZamakonaSensorDisplayComponent } from './components/zamakona-sensors/zamakona-sensor-display/zamakona-sensor-display.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

// const config: SocketIoConfig = { url: environment.backendUrl + '', options: {path:''} };
const config: SocketIoConfig = { url: environment.backendUrl + '', options: {path:'/back/'} };
@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    DashboardComponent,
    CurrentDashboardComponent,
    BuildingsComponent,
    RoomsComponent,
    SensorsComponent,
    BuildingDialogComponent,
    RoomDialogComponent,
    SensorDialogComponent,
    MagneticDetailComponent,
    AdvancedAmbienceDetailComponent,
    SwitchDetailComponent,
    SensorsTypesComponent,
    NotificationsComponent,
    MapComponent,
    RoomMapComponent,
    DialogRoomMapComponent,
    ActionsComponent,
    ZamakonaSensorsComponent,
    RoomTypePipe,
    BuildingTypePipe,
    CachedSrcDirective,
    ZamakonaSensorDetailDialogComponent,
    ZamakonaWaterQualityDetailComponent,
    ZamakonaAirQualityDetailComponent,
    ZamakonaWeatherStationDetailComponent,
    ZamakonaSensorRealTimeDashboardComponent,
    ZamakonaCamerasComponent,
    ZamakonaCameraDetailComponent,
    ZamakonaPlcComponent,
    ZamakonaPlcDetailComponent,
    ZamakonaSensorSpeedmeterComponent,
    ZamakonaSensorCompassComponent,
    ZamakonaSensorDisplayComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSelectModule,
    MatGridListModule,
    MatCheckboxModule,
    MatListModule,
    MatToolbarModule,
    MatCardModule,
    PerfectScrollbarModule,
    MatChipsModule,
    GaugeChartModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    SocketIoModule.forRoot(config),
    MatCheckboxModule,
    MatPaginatorModule,
    GoogleMapsModule,
    MatTooltipModule,
    MatExpansionModule,
    MatTreeModule,
    MatButtonToggleModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    DatePipe,
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorInternational,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
