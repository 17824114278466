<!-- <div class="dashboard-container">
  <iframe width="100%" height="270px" frameBorder="0" [src]="grafanaUrlSafe"></iframe>
</div> -->
<div class="sensor-title">
  <h1>Gráficas</h1>


</div>

<div style="display: flex; flex-direction: row; align-items: baseline; justify-content: end; 
  gap: 40px; position: relative; background-color: #2366be; padding-right: 10px; 
  padding-bottom: 10px; border-bottom-right-radius: 4px; border-bottom-left-radius: 4px;">
  <div style="width: 100%; display: flex; justify-content: space-between; align-items: end; height: 40px;color:white">
    <p style="padding-left: 15px; font-weight: 500;">Últimas {{selectedTime}}h desde la observación.</p>
  </div>
  <div style="display: flex; flex-direction: row; gap: 60px;">
    <div style="display: flex; flex-direction: row; align-items: baseline; justify-content: end; gap: 10px;">
      <p style="color:white; font-size: 16px;">Sensor</p>
      <mat-button-toggle-group class="toogle-button" name="Sensor" aria-label="Sensor" value="1"
        #group="matButtonToggleGroup">
        <mat-button-toggle value="3" (change)="onSensorChange($event.value)">A. Ambiental</mat-button-toggle>
        <mat-button-toggle value="2" (change)="onSensorChange($event.value)">A. Operacional</mat-button-toggle>
        <mat-button-toggle value="1" (change)="onSensorChange($event.value)">Acuático</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div style="display: flex; flex-direction: row; align-items: baseline; justify-content: end; gap: 10px;width: 300px; ">
      <p style="color:white; font-size: 16px;">Rango de tiempo</p>
      <mat-button-toggle-group class="toogle-button" name="Tiempo" aria-label="Tiempo" value="12"
        #group="matButtonToggleGroup">
        <mat-button-toggle value="6" (change)="onTimeChange($event.value)">6h</mat-button-toggle>
        <mat-button-toggle value="12" (change)="onTimeChange($event.value)">12h</mat-button-toggle>
        <mat-button-toggle value="24" (change)="onTimeChange($event.value)">24h</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</div>

<div class="dashboard-container" *ngIf="selectedSensor==3">
  <iframe [src]="WeatherGrafanaURL" width="100%" height="620px" frameborder="0"></iframe>
</div>

<div class="dashboard-container" *ngIf="selectedSensor==2">
  <iframe width="100%" height="620px" frameBorder="0" [src]="AirGrafanaURL"></iframe>
</div>

<div class="dashboard-container" *ngIf="selectedSensor==1">
  <iframe width="100%" height="620px" frameBorder="0" [src]="WaterGrafanaURL"></iframe>
</div>