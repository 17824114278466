/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NotificationService } from './services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ui-smartbuilding';

  constructor(
    private notificationService: NotificationService,
    private snackbar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit(): void {
    // this.notificationService.countNotificationsNoRead().subscribe((num) => {
    //   this.notificationService.notificationsNotRead = num;
    // });
    // this.notificationService.newNotification().subscribe((not) => {
    //   this.notificationService.notificationsNotRead++;
    //   this.snackbar.open('New Notification!', '', { duration: 5000 });
    //   if (this.router.url.includes('notifications') && not.buildingName === this.notificationService.building && not.roomName === this.notificationService.room &&
    //     (not.level === this.notificationService.level || this.notificationService.level === 'all') &&
    //     (this.notificationService.read === 'false' || this.notificationService.read === 'all')) {
    //     this.notificationService.getNotificationsLevelReadBeforeDate().subscribe((notifications) => {
    //       this.notificationService.notificationsFiltered = notifications;
    //       this.notificationService.clasifyNotifications(this.notificationService.building,
    //           this.notificationService.room);
    //     });
    //   }
    // });
  }
}
